import React from "react";
import { X } from "react-feather";
import Modal from "react-modal";

interface ModalProps {
    open: boolean,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>,
    children: React.ReactNode,
    label: string,
}

const DefaultModal = ({ open, setOpen, children, label }: ModalProps) => {
    return (
        <div>
            <Modal
                isOpen={open}
                contentLabel={label}
                className={`w-[90vw] md:w-[45vw] max-h-[90vh] overflow-y-auto bg-white rounded-lg p-[10px] lg:p-[30px]`}
                overlayClassName="fixed left-0 right-0 top-0 bottom-0 bg-black bg-opacity-50 flex justify-center items-center"
            >
                <div className="p-8">
                    <div className="flex justify-end">
                        <X size={30} onClick={() => setOpen(false)} className="cursor-pointer" />
                    </div>
                    {children}
                </div>
            </Modal>
        </div>
    )
}

export {
    DefaultModal,
}