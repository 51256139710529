"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CountryCodes = exports.UserRoles = void 0;
var UserRoles;
(function (UserRoles) {
    UserRoles["SMS_REFERRAL_ADMIN"] = "SMS_REFERRAL_ADMIN";
    UserRoles["OWNER"] = "OWNER";
    UserRoles["TEAM_MEMBER"] = "TEAM_MEMBER";
})(UserRoles || (UserRoles = {}));
exports.UserRoles = UserRoles;
;
var CountryCodes;
(function (CountryCodes) {
    CountryCodes["USA"] = "us";
    CountryCodes["CANADA"] = "ca";
})(CountryCodes || (CountryCodes = {}));
exports.CountryCodes = CountryCodes;
;
