import React, { ReactNode} from "react";
import { WindowDataProvider } from "../context/window-context";

const wrapPagesDeep = ({ element } : { element: ReactNode }) => {
    return (
        <div>
            <WindowDataProvider>
                {element}
            </WindowDataProvider>
        </div>
    )
}

export default wrapPagesDeep;

export {
    wrapPagesDeep,
}