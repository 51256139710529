exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/About.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-dashboard-edit-campaign-campaign-id-tsx": () => import("./../../../src/pages/dashboard/edit-campaign/[CampaignId].tsx" /* webpackChunkName: "component---src-pages-dashboard-edit-campaign-campaign-id-tsx" */),
  "component---src-pages-dashboard-index-tsx": () => import("./../../../src/pages/dashboard/index.tsx" /* webpackChunkName: "component---src-pages-dashboard-index-tsx" */),
  "component---src-pages-dashboard-onboarding-tsx": () => import("./../../../src/pages/dashboard/onboarding.tsx" /* webpackChunkName: "component---src-pages-dashboard-onboarding-tsx" */),
  "component---src-pages-dashboard-organization-tsx": () => import("./../../../src/pages/dashboard/organization.tsx" /* webpackChunkName: "component---src-pages-dashboard-organization-tsx" */),
  "component---src-pages-dashboard-view-campaigns-tsx": () => import("./../../../src/pages/dashboard/view-campaigns.tsx" /* webpackChunkName: "component---src-pages-dashboard-view-campaigns-tsx" */),
  "component---src-pages-embed-org-name-org-id-campaign-id-find-referrals-tsx": () => import("./../../../src/pages/embed/[OrgName]/[OrgId]/[CampaignId]/find-referrals.tsx" /* webpackChunkName: "component---src-pages-embed-org-name-org-id-campaign-id-find-referrals-tsx" */),
  "component---src-pages-embed-org-name-org-id-campaign-id-index-tsx": () => import("./../../../src/pages/embed/[OrgName]/[OrgId]/[CampaignId]/index.tsx" /* webpackChunkName: "component---src-pages-embed-org-name-org-id-campaign-id-index-tsx" */),
  "component---src-pages-embed-org-name-org-id-campaign-id-referral-bar-tsx": () => import("./../../../src/pages/embed/[OrgName]/[OrgId]/[CampaignId]/referral-bar.tsx" /* webpackChunkName: "component---src-pages-embed-org-name-org-id-campaign-id-referral-bar-tsx" */),
  "component---src-pages-embed-org-name-org-id-campaign-id-tiers-tsx": () => import("./../../../src/pages/embed/[OrgName]/[OrgId]/[CampaignId]/tiers.tsx" /* webpackChunkName: "component---src-pages-embed-org-name-org-id-campaign-id-tiers-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-pricing-tsx": () => import("./../../../src/pages/Pricing.tsx" /* webpackChunkName: "component---src-pages-pricing-tsx" */),
  "component---src-pages-redirect-login-tsx": () => import("./../../../src/pages/redirect/login.tsx" /* webpackChunkName: "component---src-pages-redirect-login-tsx" */),
  "component---src-pages-redirect-sign-up-tsx": () => import("./../../../src/pages/redirect/sign-up.tsx" /* webpackChunkName: "component---src-pages-redirect-sign-up-tsx" */),
  "component---src-pages-showcase-steep-and-mellow-tsx": () => import("./../../../src/pages/showcase-steep-and-mellow.tsx" /* webpackChunkName: "component---src-pages-showcase-steep-and-mellow-tsx" */)
}

