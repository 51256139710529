import React, { Fragment, useState, useContext, useEffect } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import {
  Bars3Icon,
  HomeIcon,
  XMarkIcon,
  PlusIcon,
  TableCellsIcon,
  QuestionMarkCircleIcon,
  HandRaisedIcon,
  BuildingOfficeIcon
} from '@heroicons/react/24/outline'
import { FirebaseAuthContext, Logout } from '../context/firebase-auth';
import { Link, navigate } from 'gatsby';
import { SharedDataContext } from '../context/shared-data';
import { Spinner } from './Spinner';
import { DefaultModal } from './Modal';
import AddToSite from './AddToSite';
import { EditCampaign } from './Campaign';

interface Props {
    children: React.ReactNode,
}

function DashboardLayout({ children }: Props) {
    const [sidebarOpen, setSidebarOpen] = useState(false)
    const user = useContext(FirebaseAuthContext);
    const { data, setData } = useContext(SharedDataContext);
    const [createCampModal, setCreateCampModal] = useState<boolean>(false);
    const [campId, setCampId] = useState<number>(0);
    const [orgId, setOrgId] = useState<number>(0);
    const [addToSiteModal, setAddToSiteModal] = useState<boolean>(false);
    const [showDiv, setShowDiv] = useState<boolean>(false); // hide tier list

    useEffect(() => {
        const userLoggingIn = window.localStorage.getItem("user-logging-in") || "false";
        const userLoggedInBool = JSON.parse(userLoggingIn);
        if (!userLoggedInBool && user === null) {
            navigate("/");
        }
    }, [user]);

    const navigation = [
        { name: 'Home', href: '/dashboard', external: false, icon: HomeIcon, current: false },
        { name: 'Create Campaign', href: '#', external: false, onClick: () => { setCreateCampModal(true); setSidebarOpen(false); setAddToSiteModal(false) }, icon: PlusIcon, current: false },
        { name: 'View Campaigns', href: '/dashboard/view-campaigns', external: false, icon: TableCellsIcon, current: false },
        { name: 'Request a Feature', href: 'mailto:joshua@cloudsafari.io?subject=Feature Request for SMS Loops', external: true, icon: HandRaisedIcon, current: false },
        { name: 'Get Help', href: 'https://calendly.com/joshdsouza_/30-minute-meeting', external: true, icon: QuestionMarkCircleIcon, current: false },
        { name: 'Organization Settings', href:"/dashboard/organization", external: false, icon: BuildingOfficeIcon, current: false}
      ];

    return (
        <>
            <DefaultModal
                open={createCampModal}
                setOpen={setCreateCampModal}
                label="Create Campaign"
            >
                {
                    addToSiteModal ?
                        <AddToSite
                            CampaignId={campId}
                            OrgId={orgId}
                            OrgName={data.Company.Name}
                            onboarding={false}
                            onAfterUpdate={() => {
                                setCreateCampModal(false);
                                setAddToSiteModal(false);
                                setShowDiv(true)
                            }}
                        />
                    :
                    createCampModal ?
                    <>
                        <EditCampaign
                        showDiv={showDiv}
                        onAfterUpdate={() => { 
                            setAddToSiteModal(true);
                        }}
                        setCampaignAndOrgId={(campId, orgId) => {
                            setCampId(campId);
                            setOrgId(orgId);
                        }}
                        />
                        </>             
                    :
                    null
                }
                
            </DefaultModal>

        <div>
            <Transition.Root show={sidebarOpen} as={Fragment}>
            <Dialog as="div" className="relative z-40 md:hidden" onClose={setSidebarOpen}>
                <Transition.Child
                as={Fragment}
                enter="transition-opacity ease-linear duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity ease-linear duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                >
                <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
                </Transition.Child>

                <div className="fixed inset-0 z-40 flex">
                <Transition.Child
                    as={Fragment}
                    enter="transition ease-in-out duration-300 transform"
                    enterFrom="-translate-x-full"
                    enterTo="translate-x-0"
                    leave="transition ease-in-out duration-300 transform"
                    leaveFrom="translate-x-0"
                    leaveTo="-translate-x-full"
                >
                    <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-white">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-in-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in-out duration-300"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="absolute top-0 right-0 -mr-12 pt-2">
                        <button
                            type="button"
                            className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                            onClick={() => setSidebarOpen(false)}
                        >
                            <span className="sr-only">Close sidebar</span>
                            <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                        </button>
                        </div>
                    </Transition.Child>
                    <div className="h-0 flex-1 overflow-y-auto pt-5 pb-4">
                        <div className="flex flex-shrink-0 items-center px-4">
                            {
                                data.LoadingCompany ?
                                <Spinner />
                                :
                                <img src={data.Company.LogoUrl ? data.Company.LogoUrl : "" } className="h-8 w-auto object-contain" />
                            }
                        </div>
                        <nav className="mt-5 space-y-1 px-2">
                        {navigation.map((item) => (
                            item.onClick ?
                            <button
                            key={item.name}
                            onClick={() => item.onClick()}
                            className={`
                                ${item.current
                                ? 'bg-gray-100 text-gray-900'
                                : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900'}
                                'group flex items-center px-2 py-2 text-base font-medium rounded-md'
                            `}
                            >
                            <item.icon
                                className={`
                                ${item.current ? 'text-gray-500' : 'text-gray-400 group-hover:text-gray-500'}
                                'mr-4 flex-shrink-0 h-6 w-6'
                                `}
                                aria-hidden="true"
                            />
                            <span className='ml-4'>
                            {item.name}
                            </span>
                            </button>
                            :
                            <a
                            key={item.name}
                            href={item.href}
                            className={`
                                ${item.current
                                ? 'bg-gray-100 text-gray-900'
                                : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900'}
                                'group flex items-center px-2 py-2 text-base font-medium rounded-md'
                            `}
                            target={`${item.external ? "_blank" : ""}`}
                            >
                            <item.icon
                                className={`
                                ${item.current ? 'text-gray-500' : 'text-gray-400 group-hover:text-gray-500'}
                                'mr-4 flex-shrink-0 h-6 w-6'
                                `}
                                aria-hidden="true"
                            />
                            <span className='ml-4'>
                            {item.name}
                            </span>
                            </a>
                        ))}
                        </nav>
                    </div>
                    <div className="flex flex-shrink-0 border-t border-gray-200 p-4">
                        <a href="#" className="group block flex-shrink-0">
                        <div className="flex items-center">
                            <div>
                                {
                                    data.LoadingCompany ?
                                    <Spinner />
                                    :
                                    <img src={data.Company.LogoUrl ? data.Company.LogoUrl : "" } className="inline-block h-10 w-10 rounded-full object-contain" />
                                }
                            </div>
                            <div className="ml-3">
                                <p className="text-sm font-medium text-gray-700 group-hover:text-gray-900">{data?.Company?.Name}</p>
                                <button 
                                    onClick={() => Logout("/")}
                                    className="mt-3 text-sm py-1 text-white w-full rounded bg-red-500 hover:bg-red-600">
                                        Logout
                                </button>
                            </div>
                        </div>
                        </a>
                    </div>
                    </Dialog.Panel>
                </Transition.Child>
                <div className="w-14 flex-shrink-0">{/* Force sidebar to shrink to fit close icon */}</div>
                </div>
            </Dialog>
            </Transition.Root>

            {/* Static sidebar for desktop */}
            <div className="hidden md:fixed md:inset-y-0 md:flex md:w-64 md:flex-col">
            {/* Sidebar component, swap this element with another sidebar if you like */}
            <div className="flex min-h-0 flex-1 flex-col border-r border-gray-200 bg-white">
                <div className="flex flex-1 flex-col overflow-y-auto pt-5 pb-4">
                <div className="flex flex-shrink-0 items-center px-4">
                    {
                        data.LoadingCompany ?
                        <Spinner />
                        :
                        <img src={data.Company.LogoUrl ? data.Company.LogoUrl : "" } className="h-8 w-auto object-contain" />
                    }
                </div>
                <nav className="mt-5 flex-1 space-y-1 bg-white px-2">
                    {navigation.map((item) => (
                        item.onClick ?
                    <button
                        key={item.name}
                        onClick={() => item.onClick()}
                        className={`
                        ${item.current ? 'bg-gray-100 text-gray-900' : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900'}
                        'group flex items-center px-2 py-2 text-sm font-medium rounded-md'
                        `}
                    >
                        <item.icon
                        className={`
                            ${item.current ? 'text-gray-500' : 'text-gray-400 group-hover:text-gray-500'}
                            'mr-3 flex-shrink-0 h-6 w-6'
                        `}
                        aria-hidden="true"
                        />
                        <span className="ml-3">
                        {item.name}
                        </span>
                    </button>
                    :
                    <Link
                    key={item.name}
                    to={item.href}
                    className={`
                    ${item.current ? 'bg-gray-100 text-gray-900' : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900'}
                    'group flex items-center px-2 py-2 text-sm font-medium rounded-md'
                    `}
                    activeStyle={{ background: "rgb(243 244 246)", color: "rgb(17 24 39)" }}
                    target={`${item.external ? "_blank" : ""}`}
                    >
                        <item.icon
                        className={`
                            ${item.current ? 'text-gray-500' : 'text-gray-400 group-hover:text-gray-500'}
                            'mr-3 flex-shrink-0 h-6 w-6'
                        `}
                        aria-hidden="true"
                        />
                        <span className="ml-3">
                        {item.name}
                        </span>
                    </Link>
                    ))}
                </nav>
                </div>
                <div className="flex flex-shrink-0 border-t border-gray-200 p-4">
                <a href="#" className="group block w-full flex-shrink-0">
                    <div className="flex items-center">
                    <div>
                        {
                            data.LoadingCompany ?
                            <Spinner />
                            :
                            <img src={data.Company.LogoUrl ? data.Company.LogoUrl : "" } className="inline-block h-9 w-9 rounded-full object-contain" />
                        }
                    </div>
                    <div className="ml-3">
                        <p className="text-sm font-medium text-gray-700 group-hover:text-gray-900">{data?.Company?.Name}</p>
                        <button 
                            onClick={() => Logout("/")}
                            className="mt-3 text-sm py-1 text-white w-full rounded bg-red-500 hover:bg-red-600">
                                Logout
                        </button>
                    </div>
                    </div>
                </a>
                </div>
            </div>
            </div>
            <div className="flex flex-1 flex-col md:pl-64">
            <div className="sticky top-0 z-10 bg-white pl-1 pt-1 sm:pl-3 sm:pt-3 md:hidden">
                <button
                type="button"
                className="-ml-0.5 -mt-0.5 inline-flex h-12 w-12 items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                onClick={() => setSidebarOpen(true)}
                >
                <span className="sr-only">Open sidebar</span>
                <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                </button>
            </div>
            <main className="flex-1">
                <div className="py-6">
                <div className="mx-auto max-w-7xl px-4 sm:px-6 md:px-8">
                    {/* Replace with your content */}
                    <div className="py-4">
                        {children}
                    </div>
                    {/* /End replace */}
                </div>
                </div>
            </main>
            </div>
        </div>
        </>
    )
}

export {
    DashboardLayout,
}