import React from "react";

const people = [
    { name: 'Lindsay Walton', title: 'Front-end Developer', email: 'lindsay.walton@example.com', role: 'Member' },
    // More people...
]
  
interface Props {
    tableHeaders: string[],
    tableData: Array<Array<React.ReactNode>>,
    actionColumnName?: string,
    title: string,
    description: string,
    action?: React.ReactNode,
}

function Table({ tableHeaders, tableData, title, description, action, actionColumnName }: Props) {
    return (
      <div className="w-full">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h2 className="text-xl font-semibold text-gray-900">{title}</h2>
            <p className="mt-2 text-sm text-gray-700">
                {description}
            </p>
          </div>
          {
            action ?
            <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                {action}
            </div>
            :
            null
          }
        </div>
        <div className="mt-8 flex flex-col">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-gray-50">
                    <tr>
                        {
                            tableHeaders.map((header) => (
                                <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                    {header}
                                </th>
                            ))
                        }
                        {
                            actionColumnName ?
                            <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                                <span className="sr-only">{actionColumnName}</span>
                            </th>
                            :
                            null
                        }
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {tableData.map((nodes, index) => {
                      return (
                        <tr key={index}>
                        {
                            nodes.map((nodeVal, nodeIndex) => {
                                if (nodeIndex+1 === nodes.length && actionColumnName) {
                                    return (
                                        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-left text-sm font-medium sm:pr-6 text-black">
                                            {nodeVal}
                                        </td>
                                    )
                                } else {
                                    return (
                                        <td className="text-wrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 text-black">
                                            {nodeVal}
                                        </td>
                                    )
                                }
                            })
                        }
                      </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
}

export {
    Table,
}
  