import React from "react";

interface Props {
    title: string,
    description: string,
    onClick: Function,
    actionName: string,
};

function HeadingWithAction({ title, description, onClick, actionName }: Props) {
    return (
      <div className="border-b border-gray-200 pb-5 sm:flex sm:items-center sm:justify-between">
        <div>
        <h3 className="text-lg font-medium leading-6 text-gray-900">{title}</h3>
        {
            description ?
            <p className="mt-2 max-w-4xl text-sm text-gray-500">
                {description}
            </p>
            :
            null
        }
        </div>

        <div className="mt-3 sm:mt-0 sm:ml-4">
          <button
            onClick={() => onClick()}
            type="button"
            className="inline-flex items-center rounded-md border border-transparent bg-primary px-4 py-2 text-sm font-medium text-black shadow-sm hover:bg-[#D9F548] focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
          >
            {actionName}
          </button>
        </div>
      </div>
    )
}

export {
    HeadingWithAction,
}
  