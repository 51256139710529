import React, { useState, useContext, useEffect } from "react";
import Campaign, { 
    getDefaultCampaign, instanceOfCampaign, StyleSettings,
    instanceOfButtonStyle, instanceOfContainerStyle, instanceOfStyleSettings 
} from "client-models/build/campaign";
import DatePicker from "react-datepicker";
import { sendPostRequest } from "../util/util";
import { FirebaseAuthContext } from "../context/firebase-auth";
import { CountryCodes } from "client-models/build/constants";
import { Spinner } from "./Spinner";
import { SimpleHeading } from "./SimpleHeading";
import { HeadingWithAction } from "./HeadingWithAction";
import { AddTiers } from "./Tiers";
import { Table } from "./Table";


interface EditCampaign {
    showDiv: boolean,
    onAfterUpdate: () => void,
    setCampaignAndOrgId: (campId: number, orgId: number) => void,
    campaignId?: number,
};

interface RewardTier {
    Id: number;
    Tier: number;
    Reward: string;
    TextToSend: string;
}

const EditCampaign = ({showDiv, onAfterUpdate, setCampaignAndOrgId, campaignId }: EditCampaign) => {
    const [campaign, setCampaign] = useState<Campaign>(getDefaultCampaign());
    const [inputTier, setInputTier] = useState<number>(0)
    const [inputReward, setInputReward] = useState<string>("")
    const [inputTextToSend, setInputTextToSend] = useState<string>("")
    const [tiers, setTiers] = useState<RewardTier[]>([]);
    const [tierNodes, setTierNodes] = useState<Array<Array<React.ReactNode>>>([]);
    const [loadCampaign, setLoadCampaign] = useState<boolean>(true);
    const [canada, setCanada] = useState<boolean>(false);
    const [usa, setUsa] = useState<boolean>(false);
    const user = useContext(FirebaseAuthContext);
    const [styles, setStyles] = useState<StyleSettings>({
        container: {
            backgroundColor: "#FFFFFF",
            fontFamily: "Chivo, sans-serif",
            color: "#000000",
        },
        button: {
            backgroundColor: "white",
            borderColor: "#000000",
            color: "#000000",
            borderRadius: "10px"
        }
    });

    useEffect(() => {
        if (user !== null && campaignId) {
            getCampaign();
        }
    }, [user]);
    


    const getCampaign = async () => {
        if (user === null) return;
        const [resp, err] = await sendPostRequest(user, "/campaign/get-private-campaign", {CampaignId: campaignId}, "", "Failed to get the campaign");
        setLoadCampaign(false);
        if (err === null) {
            const camp = resp.Campaign;
            console.log({ camp });
            if (true) { //instanceOfCampaign(camp)
                setCampaign({
                    Id: camp.Id,
                    Name: camp.Name,
                    LiveDate: new Date(camp.LiveDate),
                    EndDate: new Date(camp.EndDate),
                    TextMessageToSend: camp.TextMessageToSend,
                    AudienceLimit: camp.AudienceLimit,
                    Published: camp.Published,
                    StyleSettings: camp.StyleSettings,
                    LandingUrl: camp.LandingUrl,
                })
                
                // Get the new styles
                let tempStyles : StyleSettings = {
                    container: {
                        backgroundColor: camp.StyleSettings?.backg,
                        fontFamily: "",
                        color: "",
                    },
                    button: {
                        backgroundColor: "",
                        color: "",
                        borderColor: "",
                        borderRadius: "",
                    },
                }
                if (camp.StyleSettings && instanceOfStyleSettings(camp.StyleSettings)) {
                    if (instanceOfButtonStyle(camp.StyleSettings.button)) {
                        tempStyles.button = {
                            ...camp.StyleSettings.button,
                        }
                    }
                    if (instanceOfContainerStyle(camp.StyleSettings.container)) {
                        tempStyles.container = {
                            ...camp.StyleSettings.container,
                        }
                    }
                }
                setStyles(tempStyles);
                
            } else {
                console.log("Failed, not an instance.")
            }

            const countryCodeMap = resp.Countries.reduce((obj : { [key: string]: boolean }, val: { country_code: string }) => {
                return { ...obj, [val.country_code]: true };
            }, {});
            if ("ca" in countryCodeMap) {
                setCanada(true);
            }
            if ("us" in countryCodeMap) {
                setUsa(true);
            }
        }
    }

    
    const updateCampaign = async () => {
      if (user === null) return;
        let cc = [];
        if (canada) {
            cc.push(CountryCodes.CANADA);
        }
        if (usa) {
            cc.push(CountryCodes.USA);
        }
        campaign.StyleSettings = styles;
        const [response, err] = await sendPostRequest(user, "/campaign/update-campaign", { Campaign: campaign, CampaignCountries: cc }, "Successfully saved the campaign.", "Failed to create the campaign.")
    }
    
    const handleCreateCampaign = async () => {
        if (user === null) return;
        let cc = [];
        if (canada) {
            cc.push(CountryCodes.CANADA);
        }
        if (usa) {
            cc.push(CountryCodes.USA);
        }
        const [response, err] = await sendPostRequest(user, "/campaign/create-campaign", { Campaign: campaign, CampaignCountries: cc }, "Successfully created the campaign.", "Failed to create the campaign.")
        if (err === null) {
            const campaignId = response.CampaignId;
            const orgId = response.OrgId;
            setCampaignAndOrgId(campaignId, orgId);
            onAfterUpdate();
        }
    }


    const saveCampaign = () => {
        if (campaignId) {
            updateCampaign()
        } else {
            handleCreateCampaign()
        }
    }

    //get all campaign tiers from database
    useEffect(() => {
        if (user !== null && campaignId && campaignId > 0 ) {
            getTiers();
        }
    }, [user, campaignId]);


//get all campaign tiers from database
    const getTiers = async () => {
        if (user === null) return;
        const [resp, err] = await sendPostRequest(user, "/campaign/get-all-tiers", {CampaignId: campaignId}, "", "Failed to get the campaign");
        setTiers(resp)
    }

    useEffect(() => {
        const tierData : Array<Array<React.ReactNode>> = tiers?.reduce((arr : Array<Array<React.ReactNode>>, val : RewardTier, index) => {
            return [
                ...arr,
                [
                    <>{val.tier}</>,
                    <>{val.reward}</>,
                    <>{val.text_to_send}</>,
                    <div className="w-full flex flex-wrap gap-2">
                     <button className="button-red" onClick={() => deleteTier(val, index)}>Delete</button>
                     </div>
                ]
            ]
        }, []);
        setTierNodes(tierData);
    }, [tiers])



      const handleCreateTier = async () => {
            
            console.log(campaign)
        let newFullTier: RewardTier = {
            Id: 53,
            Tier:inputTier,
            Reward: inputReward,
            TextToSend: inputTextToSend
         }
        if (user === null) {
                console.log("err")
         return;
        } 
        else {
       const [resp, err] = await sendPostRequest(user, "/campaign/create-tier", {tiers: newFullTier, Campaign: campaign}, "", "Failed to get the campaign");
       if(err ==null) {
        setInputTier(0);
        setInputReward("");
        setInputTextToSend("");
       }
         }
    }

     // Delete Tier
     const deleteTier = async (tier: RewardTier, index: number) => {
        if (user === null) return;
        console.log(tier)
        const [response, err] = await sendPostRequest(user, "/campaign/delete-tier", tier, "", "Failed to delete the campaign.");
        setTiers((prevState) => {
            const temp = [...prevState];
            temp.splice(index, 1);
            return temp
        })
    }

    if (campaignId && loadCampaign) {
        return (
            <div className="justify-center flex">
                <Spinner />
            </div>
        )
    }


    return (
        <div>
        {<div className='flex justify-center flex-col gap-6'>
            {
                campaignId ?
                <HeadingWithAction
                    title="Edit your campaign"
                    description={`Edit the details of your campaign below, to make this campaign go live click the Publish checkbox. `}
                    onClick={saveCampaign}
                    actionName="Save Campaign"
                />
                :
                <HeadingWithAction
                    title="Create a campaign"
                    description={`Create your campaign below. If you don't have all the details, you can edit them later. To make this campaign go live click the Publish checkbox.`}
                    onClick={saveCampaign}
                    actionName="Save Campaign"
                />
            }
            <div className='w-full'>
                <h5 className='text-normal font-medium'>Campaign Name</h5>
                <input 
                    value={campaign.Name}
                    className='input mt-2' 
                    onChange={e => {
                        setCampaign((prevState) => ({
                            ...prevState,
                            Name: e.target.value,
                        }))
                    }}
                />
            </div>

            <div className='w-full'>
                <h5 className='text-normal font-medium'>Campaign Live Date</h5>
                <DatePicker 
                    selected={campaign.LiveDate} 
                    onChange={(date : Date) => setCampaign((prevState) => ({
                        ...prevState,
                        LiveDate: date,
                    }))} 
                    className="input"
                    showTimeSelect
                    dateFormat="Pp"
                />
                <small>
                    The date & time when your campaign will go live.
                </small>
            </div>

            <div className='w-full'>
                <h5 className='text-normal font-medium'>Campaign End Date</h5>
                <DatePicker 
                    selected={campaign.EndDate} 
                    onChange={(date : Date) => setCampaign((prevState) => ({
                        ...prevState,
                        EndDate: date,
                    }))} 
                    className="input"
                    showTimeSelect
                    dateFormat="Pp"
                />
                <small>
                    The date & time when your campaign will end.
                </small>
            </div>

            <div className='w-full'>
                <h5 className='text-normal font-medium'>Text Message to Send</h5>
                <textarea
                    rows={5}
                    value={campaign.TextMessageToSend}
                    className='input my-2 text-sm' 
                    onChange={e => {
                        setCampaign((prevState) => ({
                            ...prevState,
                            TextMessageToSend: e.target.value
                        }))
                    }}
                    maxLength={500}
                />
                <small>
                 This is the message that will be sent to your customers when they receive their auth code. Note, this is MAXIMUM 500 characters.
                </small>
            </div>

            <div className='w-full'>
                <h5 className='text-normal font-medium'>Campaign Landing Page URL</h5>
                <input
                    value={campaign.LandingUrl}
                    className='input my-2' 
                    onChange={e => {
                        setCampaign((prevState) => ({
                            ...prevState,
                            LandingUrl: e.target.value,
                        }))
                    }}
                />
                <small>
                    Users will use this landing page URL when sending their referral link to others.
                </small>
            </div>

            <div className='w-full'>
                <h5 className='text-normal font-medium'>Audience Limit</h5>
                <input 
                    type="number"
                    min={0}
                    value={campaign.AudienceLimit}
                    className='input my-2' 
                    onChange={e => {
                        let number = +String(e.target.value);
                        if (!isNaN(number)) {
                            if (number > 1000) {
                                number = 1000;
                            } else if (number < 0) {
                                number = 0;
                            }
                            setCampaign((prevState) => ({
                                ...prevState,
                                AudienceLimit: number,
                            }))
                        }
                    }}
                />
                <small>
                    Limit the number of people that can sign up for your campaign. The current limit based on your tier is 1000. Note, this is the amount of verified users.
                </small>
            </div>

            <div className='w-full'>
                <div className="flex items-center gap-2 mb-2">
                    <input 
                        type="checkbox"
                        checked={usa}
                        onChange={e => setUsa(e.target.checked)}
                    />
                    <h5 className='text-normal font-medium'>Availble in the USA?</h5>
                </div>
                <div className="flex items-center gap-2 mb-2">
                    <input 
                        type="checkbox"
                        checked={canada}
                        onChange={e => setCanada(e.target.checked)}
                    />
                    <h5 className='text-normal font-medium'>Availble in Canada?</h5>
                </div>
                <small>
                    Restrict the numbers that can sign up to the United States or Canada.
                </small>
            </div>

            <div className='w-full'>
                <div className="flex items-center gap-2 mb-2">
                    <input 
                        type="checkbox"
                        checked={campaign.Published}
                        onChange={e => setCampaign((prevState) => {
                            return {
                                ...prevState,
                                Published: e.target.checked
                            }
                        })}
                    />
                    <h5 className='text-normal font-medium'>Publish</h5>
                </div>
                <small>
                    Publish your campaign to make it live.
                </small>
            </div>

            {showDiv && <div id="tierList" className="w-full my-5">
            <Table
                tableHeaders={["Tier Amount", "Reward", "Text to Send", "Actions"]}
                tableData={tierNodes}
                actionColumnName="Actions"
                title="Add Reward Tier"
                description="Create a tiered reward system for users. Once a referral tier is acheived, we'll send an SMS to a user with their reward."
            />
                <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 mt-6">
                <div className="mb-4">
                    <label htmlFor="tier" className="block text-gray-700 font-bold mb-2">Tier (Amount of referrals to get reward):</label>
                    <input type="number" value={inputTier} onChange={e => {setInputTier(e.target.valueAsNumber)}} id="tier" name="tier" min="1"  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" required />
                </div>

                <div className="mb-4">
                    <label htmlFor="reward" className="block text-gray-700 font-bold mb-2">Reward:</label>
                    <input type="text" placeholder="30% Discount Code!" value={inputReward} onChange={e => {setInputReward(e.target.value)}} id="reward" name="reward" className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" required />
                </div>

                <div className="mb-4">
                    <label htmlFor="message" className="block text-gray-700 font-bold mb-2">Text to Send:</label>
                    <textarea id="message" placeholder="Congratulations! Your Referral Code has been used 10 times. You have recieved the 30% off Discount Code: CXD5R" value={inputTextToSend} onChange={e => {setInputTextToSend(e.target.value)}} name="message" className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" required></textarea>
                </div>
                <div className="flex items-center justify-center">
                </div>

                <button
                    className="button-primary"
                    onClick={() => {
                        handleCreateTier()
                        getTiers()
                    }}
                >
                    Add Tier
                </button>
            </div>
            
    
            </div>}

            <div className="my-5 w-full">
                <h5 className='text-normal font-bold mb-2'>Style Settings</h5>
                <h5 className='text-normal mb-2'>
                    Add some basic styles to change the theme of the campaign when you add it to your landing page.
                </h5>
                <div className="pt-2">
                    <h5 className="font-bold">Container Style</h5>
                    <h5 className='text-normal mb-2'>
                        The container style is the entire iFrame you add to your page.
                    </h5>
                    <div className="flex gap-2 items-center my-3">
                        <div>Background Color</div>
                        <div>
                            <input className="input"
                                value={styles.container.backgroundColor}
                                onChange={e => {
                                    setStyles(prevState => {
                                        return {
                                            ...prevState,
                                            container: {
                                                ...prevState.container,
                                                backgroundColor: e.target.value,
                                            }
                                        }
                                    })
                                }}
                            />
                        </div>
                    </div>
                    <div className="flex gap-2 items-center my-3">
                        <div>Font Family</div>
                        <div>
                            <input className="input"
                                value={styles.container.fontFamily}
                                onChange={e => {
                                    setStyles(prevState => {
                                        return {
                                            ...prevState,
                                            container: {
                                                ...prevState.container,
                                                fontFamily: e.target.value,
                                            }
                                        }
                                    })
                                }}
                            />
                        </div>
                    </div>
                    <div className="flex gap-2 items-center my-3">
                        <div>Text Color</div>
                        <div>
                            <div>
                                <input className="input"
                                    value={styles.container.color}
                                    onChange={e => {
                                        setStyles(prevState => {
                                            return {
                                                ...prevState,
                                                container: {
                                                    ...prevState.container,
                                                    color: e.target.value,
                                                }
                                            }
                                        })
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="pt-8">
                    <h5 className="font-bold">Button Style</h5>
                    <h5 className='text-normal mb-2'>
                        The button style applies to all buttons used in the iFrame.
                    </h5>
                    <div className="flex gap-2 items-center my-3">
                        <div>Background Color</div>
                        <div>
                            <input className="input"
                                value={styles.button.backgroundColor}
                                onChange={e => {
                                    setStyles(prevState => {
                                        return {
                                            ...prevState,
                                            button: {
                                                ...prevState.button,
                                                backgroundColor: e.target.value,
                                            }
                                        }
                                    })
                                }}
                            />
                        </div>
                    </div>
                    <div className="flex gap-2 items-center my-3">
                        <div>Border Color</div>
                        <div>
                            <input className="input"
                                value={styles.button.borderColor}
                                onChange={e => {
                                    setStyles(prevState => {
                                        return {
                                            ...prevState,
                                            button: {
                                                ...prevState.button,
                                                borderColor: e.target.value,
                                            }
                                        }
                                    })
                                }}
                            />
                        </div>
                    </div>
                    <div className="flex gap-2 items-center my-3">
                        <div>Text Color</div>
                        <div>
                            <input className="input"
                                value={styles.button.color}
                                onChange={e => {
                                    setStyles(prevState => {
                                        return {
                                            ...prevState,
                                            button: {
                                                ...prevState.button,
                                                color: e.target.value,
                                            }
                                        }
                                    })
                                }}
                            />
                        </div>
                    </div>
                    <div className="flex gap-2 items-center my-3">
                        <div>Border Radius</div>
                        <div>
                            <input className="input"
                                value={styles.button.borderRadius}
                                onChange={e => {
                                    setStyles(prevState => {
                                        return {
                                            ...prevState,
                                            button: {
                                                ...prevState.button,
                                                borderRadius: e.target.value,
                                            }
                                        }
                                    })
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="w-full flex justify-end">
                <button
                    className="button-primary"
                    onClick={() => {
                        if (campaignId) {
                            updateCampaign()
                        } else {
                            handleCreateCampaign()
                        }
                    }}
                >
                    Save Campaign
                </button>
                
            </div>


        </div>}
        </div>
    )
}

export {
    EditCampaign,
}