import Organization, { getDefaultOrganization, instanceOfOrganization } from "client-models/build/organization";
import * as React from "react";
import { sendPostRequest } from "../util/util";
import { FirebaseAuthContext } from "./firebase-auth";

interface SharedData {
    Company: Organization
    LoadingCompany: boolean
};

function isSharedData(o: any): o is SharedData {
    return "Company" in o;
}

interface SharedDataValue {
    data: SharedData;
    setData: React.Dispatch<React.SetStateAction<SharedData>>;
}


const defaultSharedData : SharedData = {
    Company: getDefaultOrganization(),
    LoadingCompany: true,
};

const initialSharedData : SharedDataValue = {
    data: defaultSharedData,
    setData: () => {},
};

const SharedDataContext = React.createContext(initialSharedData);

interface Props {
    children: React.ReactNode;
}

const SharedDataProvider: React.FC<Props>  = ({ children }) => {
    const [data, setData] = React.useState<SharedData>(defaultSharedData);
    const user = React.useContext(FirebaseAuthContext);

    React.useEffect(() => {
        getCompanyData();
    }, [user])

    const getCompanyData = async () => {
        if (user === null) return;
        const [response, err] = await sendPostRequest(user, "/organization/get-organization", {}, "", "Failed to get the company data.");
        if (err === null) {
            if (instanceOfOrganization(response)) {
                setData((prevState) => {
                    return {
                        ...prevState,
                        Company: response,
                        LoadingCompany: false,
                    }
                })
            }
        }
    }
    
    return (
        <SharedDataContext.Provider value={{ data, setData }}>
            {children}
        </SharedDataContext.Provider>
    )
}

export {
    SharedDataContext,
    SharedDataProvider,
}