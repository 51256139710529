"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfStyleSettings = exports.instanceOfContainerStyle = exports.instanceOfButtonStyle = exports.getDefaultCampaign = exports.instanceOfCampaign = void 0;
;
const getDefaultCampaign = () => {
    return {
        Id: 0,
        Name: "",
        EndDate: new Date(),
        LiveDate: new Date(),
        TextMessageToSend: "",
        AudienceLimit: 0,
        Published: false,
        StyleSettings: {},
        LandingUrl: "",
        Tiers: [],
    };
};
exports.getDefaultCampaign = getDefaultCampaign;
const instanceOfCampaign = (object) => {
    return ('Id' in object &&
        'Name' in object &&
        'EndDate' in object &&
        'LiveDate' in object &&
        'TextMessageToSend' in object &&
        'AudienceLimit' in object &&
        'Published' in object &&
        'StyleSettings' in object &&
        'LandingUrl' in object &&
        'Tiers' in object);
};
exports.instanceOfCampaign = instanceOfCampaign;
const instanceOfStyleSettings = (object) => {
    return ('container' in object &&
        'button' in object);
};
exports.instanceOfStyleSettings = instanceOfStyleSettings;
const instanceOfContainerStyle = (object) => {
    return ('backgroundColor' in object &&
        'fontFamily' in object &&
        'color' in object);
};
exports.instanceOfContainerStyle = instanceOfContainerStyle;
const instanceOfButtonStyle = (object) => {
    return ('backgroundColor' in object &&
        'borderColor' in object &&
        'color' in object &&
        'borderRadius' in object);
};
exports.instanceOfButtonStyle = instanceOfButtonStyle;
