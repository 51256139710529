import React, { useEffect } from "react";
import { isBrowser } from "../util/util";

interface WindowData {
    protocol: string,
    host: string,
};

interface WindowDataValue {
    windowData: WindowData,
    setWindowData: React.Dispatch<React.SetStateAction<WindowData>>;
}


const defaultWindowData : WindowData = {
    protocol: "https",
    host: "smsloops.com"
};

const initialWindowData : WindowDataValue = {
    windowData: defaultWindowData,
    setWindowData: () => {},
};


const WindowDataContext = React.createContext(initialWindowData);

interface Props {
    children: React.ReactNode;
}

const WindowDataProvider: React.FC<Props>  = ({ children }) => {
    const [windowData, setWindowData] = React.useState<WindowData>({
        protocol: "https",
        host: "smsloops.com"
    });

    useEffect(() => {
        if (!isBrowser) {
            setWindowData({
                protocol: "https",
                host: "smsloops.com"
            })
        } else {
            setWindowData({
                protocol: window.location.protocol,
                host: window.location.host,
            })
        }
    }, [isBrowser])
    
    return (
        <WindowDataContext.Provider value={{ windowData, setWindowData }}>
            {children}
        </WindowDataContext.Provider>
    )
}

export {
   WindowDataProvider,
   WindowDataContext,
}