import { multiFactor } from "firebase/auth";
import { Link, navigate } from "gatsby";
import React, { ReactNode, useContext, useEffect, useState } from "react";
import { DashboardLayout } from "../../components/DashboardLayout";
import { Spinner } from "../../components/Spinner";
import { FirebaseAuthContext, getCurrentUser } from "../../context/firebase-auth";
import { SharedDataContext, SharedDataProvider } from "../../context/shared-data";

const AuthCheck = ({ element }: { element: ReactNode }) => {
    const user = useContext(FirebaseAuthContext);
    const { data, setData } = useContext(SharedDataContext);

    const [checkedUser, setCheckedUser] = useState<boolean>(false);

    useEffect(() => {
        getCurrentUser().then((usr) => {
            if (!usr) {
                navigate("/")
            }
        }).then(() => {
            setCheckedUser(true);
        })
    }, []);

    useEffect(() => {
        if (user === null && checkedUser) {
            navigate("/");
        }
    }, [user, checkedUser])

    if (user === null) {
        return <></>
    } else {
        return (
            <div>
                {
                !window.location.href.includes('/dashboard/onboarding')
                ?
                <DashboardLayout>
                <div className="flex justify-center">
                    {element}
                </div>
                </DashboardLayout>
                :
                element
                }
            </div>
        )
    }
}

const wrapPagesDeep = ({ element } : { element: ReactNode }) => {
    return (
        <div>
            <SharedDataProvider>
                <AuthCheck element={element} />
            </SharedDataProvider>
        </div>
    )
}

export default wrapPagesDeep;

export {
    wrapPagesDeep, 
    AuthCheck,
}