"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getDefaultOrganization = exports.instanceOfOrganization = void 0;
;
const instanceOfOrganization = (object) => {
    return ('Id' in object &&
        'Uuid' in object &&
        'Name' in object &&
        'LogoUrl' in object &&
        'OwnerId' in object &&
        'Settings' in object &&
        'Verified' in object &&
        'WebsiteUrl' in object &&
        'PrimaryEmail' in object);
};
exports.instanceOfOrganization = instanceOfOrganization;
const getDefaultOrganization = () => {
    return {
        Id: 0,
        Uuid: "",
        Name: "",
        LogoUrl: "https://firebasestorage.googleapis.com/v0/b/sms-referral-dev.appspot.com/o/sms-logo.svg?alt=media&token=625385ee-b51b-4401-b131-8980164498d1",
        OwnerId: 0,
        Settings: {},
        Verified: false,
        WebsiteUrl: "",
        PrimaryEmail: "",
    };
};
exports.getDefaultOrganization = getDefaultOrganization;
