import React from "react";
import { Tab } from '@headlessui/react'
import { AtSymbolIcon, CodeBracketIcon, LinkIcon } from '@heroicons/react/20/solid'
import { Copy } from "react-feather";

function classNames(...classes : string[]) {
  return classes.filter(Boolean).join(' ')
}

interface Props {
    code: string,
    embedLink: string,
    TabName: string,
}

function CodePreview({ code, embedLink, TabName }: Props) {
  return (
    <form>
      <Tab.Group>
        {({ selectedIndex }) => (
          <>
            <Tab.List className="flex items-center">
              <Tab
                className={({ selected }) =>
                  classNames(
                    selected
                      ? 'text-gray-900 bg-gray-100 hover:bg-gray-200'
                      : 'text-gray-500 hover:text-gray-900 bg-white hover:bg-gray-100',
                    'rounded-md border border-transparent px-3 py-1.5 text-sm font-medium'
                  )
                }
              >
                {TabName}
              </Tab>
              <Tab
                className={({ selected }) =>
                  classNames(
                    selected
                      ? 'text-gray-900 bg-gray-100 hover:bg-gray-200'
                      : 'text-gray-500 hover:text-gray-900 bg-white hover:bg-gray-100',
                    'ml-2 rounded-md border border-transparent px-3 py-1.5 text-sm font-medium'
                  )
                }
              >
                Preview
              </Tab>

            {/* These buttons are here simply as examples and don't actually do anything. */}
            <div className="ml-auto flex items-center space-x-5">
                <div className="flex items-center">
                <button
                    type="button"
                    className="-m-2.5 inline-flex h-10 w-10 items-center justify-center rounded-full text-gray-400 hover:text-gray-500"
                    onClick={(e) => {
                        e.preventDefault();
                        navigator.clipboard.writeText(code);
                    }}
                >
                    <span className="sr-only">Copy</span>
                    <Copy className="h-5 w-5" aria-hidden="true" />
                </button>
                </div>
            </div>

            </Tab.List>
            <Tab.Panels className="mt-2">
              <Tab.Panel className="-m-0.5 rounded-lg p-0.5">
                <label htmlFor="comment" className="sr-only">
                  Code
                </label>
                <div>
                  <textarea
                    rows={12}
                    name="comment"
                    id="comment"
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    placeholder="Edit code as needed"
                    defaultValue={''}
                    value={code}
                    disabled={true}
                  />
                  
                </div>
              </Tab.Panel>
              <Tab.Panel className="-m-0.5 rounded-lg p-0.5">
                <div className="border-b">
                  <div className="mx-px mt-px px-3 pt-2 pb-12 text-sm leading-5 text-gray-800">
                      {
                          embedLink.length === 0 ?
                          "Nothing to preview."
                          :
                          <iframe
                            src={embedLink}
                            style={{
                                minHeight: "450px",
                            }}
                            width="100%"
                            height="100%"
                            title="Campaign Preview"
                        />
                      }
                  </div>
                </div>
              </Tab.Panel>
            </Tab.Panels>
          </>
        )}
      </Tab.Group>
      <div className="mt-2 flex justify-end">
        <button
            type="submit"
            className="inline-flex items-center rounded-md border border-transparent bg-primary px-4 py-2 text-sm font-medium text-black shadow-sm hover:bg-[#D9F548] focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            onClick={(e) => {
                e.preventDefault();
                navigator.clipboard.writeText(code);
            }}
        >
          Copy
        </button>
      </div>
    </form>
  )
}

export {
    CodePreview,
}