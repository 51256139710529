import { User } from "firebase/auth";
import { toast } from "react-toastify";
import { auth } from "../context/firebase-auth";
import axios from "axios";

interface AuthConfig {
    headers: {
        authorization: string,
    }
};

const defaultAuthConfig = {
    headers: {
        authorization: "",
    }
};

const getRequestConfigWithUserToken = async (user: User | null) : Promise<[AuthConfig, Error | null]> => {
    if (!user) {
        return [defaultAuthConfig, new Error("User is not set.")];
    }

    const token = await user.getIdToken();
    
    const config : AuthConfig = {
        headers: {
            authorization: `Bearer ${token}`,
        }
    };
    return [config, null];
}

const toastSuccess = (message: string) => {
    toast.success(message, {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
}

const toastError = (message: string) => {
    toast.error(message, {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
}

const toastWarning = (message: string) => {
    toast.warning(message, {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
}

const toastInfo = (message: string) => {
    toast.info(message, {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
}

const waitOnTimeout = (ms: number) => {
    return new Promise(resolve => {
        setTimeout(resolve, ms);
    });
}

const getFirebaseUser = async () : Promise<[User | null, Error | null]> => {
    const [user, userError] = await new Promise<[User | null, Error | null]>((resolve, reject) => {
        auth.onAuthStateChanged(user => {
            if (user) {
                 resolve([user, null]);
            } else {
                resolve([null, new Error("Failed to get firebase user.")]);
            }
        }, (err) => {
            resolve([null, err]);
        })
    })

    if (userError !== null) {
        return [user, null];
    }
    return [null, userError];
}

const getCustomClaims = async (user: User) => {
    const tokenResult = await user.getIdTokenResult();
    return tokenResult;
}


const sendGenericPostRequest = async (pathname: string, data: { [key: string] : any }, successMessage: string, errorMessage: string) => {
    if (!process.env.GATSBY_BACKEND_URL) {
        return [null, new Error("No gatsby backed URL set.")];
    };
    try {
        const response = await axios.post(
            `${process.env.GATSBY_BACKEND_URL}${pathname}`,
            data,
            {},
        )
        if (response.status === 200) {
            if (successMessage.length > 0) {
                toastSuccess(successMessage)
            }
            return [response.data, null];
        } else {
            toastError(errorMessage);
            return [null, new Error("Not a 200 received from the server.")];
        }
    } catch (e) {
        console.log(e);
        if (e instanceof Error) {
            return [null, e];
        } else {
            return [null, new Error("Fatal error occurred")]
        }
    }
}

const sendPostRequest = async (user: User, pathname: string, data: { [key: string]: any }, successMessage: string, errorMessage: string): Promise<[any, Error | null]> => {
    const [config, configError] = await getRequestConfigWithUserToken(user);
    
    if (configError !== null) {
        toastError("Failed to get user config.");
        return [null, new Error("Failed to get user config.")];
    }

    if (!process.env.GATSBY_BACKEND_URL) {
        return [null, new Error("No gatsby backed URL set.")];
    };

    try {
        const response = await axios.post(
            `${process.env.GATSBY_BACKEND_URL}${pathname}`,
            data,
            config
        )
        if (response.status === 200) {
            if (successMessage.length > 0) {
                toastSuccess(successMessage)
            }
            return [response.data, null];
        } else {
            toastError(errorMessage);
            return [null, new Error("Not a 200 received from the server.")];
        }
    } catch (e) {
        console.log(e);
        toastError("Something went wrong, please contact our team.");
        if (e instanceof Error) {
            return [null, e];
        } else {
            return [null, new Error("Fatal error occurred")]
        }
    }
}

const stringToReadableUrl = (value: string) => {
    return value == undefined ? '' : value.replace(/[^a-z0-9_]+/gi, '-').replace(/^-|-$/g, '').toLowerCase();
}

const isBrowser = typeof window !== "undefined";

export {
    getRequestConfigWithUserToken,
    toastSuccess,
    toastError,
    toastWarning,
    toastInfo,
    waitOnTimeout,
    getFirebaseUser,
    getCustomClaims,
    sendPostRequest,
    sendGenericPostRequest,
    stringToReadableUrl,
    isBrowser,
}