import './src/styles/global.css'
import 'react-toastify/dist/ReactToastify.css';
import "react-datepicker/dist/react-datepicker.css";
import type { GatsbyBrowser } from "gatsby";
import { FirebaseAuthProvider } from "./src/context/firebase-auth";
import { ToastContainer } from "react-toastify";
import * as React from "react";


export const wrapPageElement: GatsbyBrowser["wrapPageElement"] = ({
  element,
}) => {

return (
    <FirebaseAuthProvider>
        {element}
        <ToastContainer
            position="bottom-center"
            autoClose={3000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
        />
</FirebaseAuthProvider>
  )
}
