import { navigate } from "gatsby";
import React, {useContext, useEffect, useState} from "react";
import { WindowDataContext } from "../context/window-context";
import { stringToReadableUrl } from "../util/util";
import { CodePreview } from "./CodePreview";
import { SimpleHeading } from "./SimpleHeading";

interface AddToSiteProps {
    CampaignId: number,
    OrgId: number,
    OrgName: string,
    onboarding: boolean,
    onAfterUpdate?: () => void,
}

const AddToSite = ({ CampaignId, OrgId, OrgName, onboarding, onAfterUpdate }: AddToSiteProps) => {
    const { windowData } = useContext(WindowDataContext);
    
    if (OrgName.length === 0) {
        OrgName = "sms-referral-onboarding"
    }

    const campaignLink = `${windowData.protocol}//${windowData.host}/embed/${stringToReadableUrl(OrgName)}/${OrgId}/${CampaignId}/`;
    const referralLink = `${windowData.protocol}//${windowData.host}/embed/${stringToReadableUrl(OrgName)}/${OrgId}/${CampaignId}/find-referrals`;
    const barLink = `${windowData.protocol}//${windowData.host}/embed/${stringToReadableUrl(OrgName)}/${OrgId}/${CampaignId}/referral-bar`;
    const tierLink = `${windowData.protocol}//${windowData.host}/embed/${stringToReadableUrl(OrgName)}/${OrgId}/${CampaignId}/tiers`;

    const code = `
    <div style="width:100%; height:100%; min-height:450px;">
        <iframe 
            id="sms-referral-${OrgId}-${CampaignId}" width="100%" height="100%" 
            style="min-height:450px"
            src="${campaignLink}" 
            title="SMS Loops Campaign" 
            frameborder="0" 
        >
        </iframe>
    </div>`;

    const referralCheckCode = `
    <div style="width:100%; height:100%; min-height:450px;">
        <iframe 
            id="sms-referral-${OrgId}-${CampaignId}-referral" width="100%" height="100%" 
            style="min-height:450px"
            src="${referralLink}" 
            title="SMS Loops Campaign" 
            frameborder="0" 
        >
        </iframe>
    </div>`;

    const barCheckCode = `
    <div style="width:100%; height:100%; min-height:200px;">
        <iframe 
            id="sms-referral-${OrgId}-${CampaignId}-bar" width="100%" height="100%" 
            style="min-height:200px"
            src="${barLink}" 
            title="SMS Loops Campaign" 
            frameborder="0" 
        >
        </iframe>
    </div>`;

    const tierCode = `
    <div style="width:100%; height:100%; min-height:450px;">
        <iframe 
            id="sms-referral-${OrgId}-${CampaignId}-tiers" width="100%" height="100%" 
            style="min-height:450px"
            src="${tierLink}" 
            title="SMS Loops Campaign" 
            frameborder="0" 
        >
        </iframe>
    </div>`;
    
    const jsCode = `
    <script>
        function handleSMSLoopsIframe() {
            try {
                const el = document.getElementById("sms-referral-${OrgId}-${CampaignId}");
                const phoneNumber = new URLSearchParams(window.location.search).get("phone-number");
                if (phoneNumber && el !== null) {
                    el.src = \`${windowData.protocol}/${windowData.host}/embed/${stringToReadableUrl(OrgName)}/${OrgId}/${CampaignId}?phone-number=\${encodeURIComponent(phoneNumber)}\`
                }
            } catch (e) {
                console.log(e);
            }
        }

        window.onload = function() {
            handleSMSLoopsIframe();
        }
    </script>
    `;

    return (
        <div className="my-24">

        <div>
        <SimpleHeading
            title="HTML for the Campaign"
            description="This is the HTML you need to embed the campaign on your page so customers can enter in their phone number."
        />
        </div>
        <div className="mt-6">
        <CodePreview 
            code={code}
            embedLink={campaignLink}
            TabName="HTML for the Campaign"
        />
        </div>

        <div className="mt-12">
        <SimpleHeading
            title="HTML for Checking Referrals"
            description="This is the HTML you need for customers to check how many people they've referred."
        />
        </div>
        <div className="mt-6">
            <CodePreview
                code={referralCheckCode}
                embedLink={referralLink}
                TabName="HTML for Checking Referrals"
            />
        </div>

        <div className="mt-12">
        <SimpleHeading
            title="HTML for Referral Bar"
            description="This is the HTML you need for customers to check how many referrals are left in the campaign"
        />
        </div>
        <div className="mt-6">
            <CodePreview
                code={barCheckCode}
                embedLink={barLink}
                TabName="HTML for Referral Bar"
            />  
        </div>

        <div className="mt-12">
        <SimpleHeading
            title="HTML for Tier list"
            description="This is the HTML you need for customers to check how many referrals are left in the campaign"
        />
         </div>
        <div className="mt-6">
            <CodePreview
                code={tierCode}
                embedLink={tierLink}
                TabName="HTML for Tiers"
            />
        </div>

        <div className="mt-12">
        <SimpleHeading
            title="Script for the Campaign"
            description="Place this script at the <head></head> of the page where your campaign is. This script is what lets us track customer referrals."
        />
        </div>
        <div className="mt-6">
            <CodePreview
                code={jsCode}
                embedLink=""
                TabName="Script for the Campaign"
            />
        </div>


        {
            window.location.href.includes("/dashboard/onboarding") ?
            <div className="w-full flex justify-end">
                <button className="button-primary" onClick={() => {
                    if (onboarding) {
                        navigate("/dashboard");
                    } else if (onAfterUpdate) {
                        onAfterUpdate();
                    }
                }}>
                    Finish
                </button>
            </div>
            :
            null
        }

        </div>
    )
}

export default AddToSite;